import React, { ReactNode } from 'react';
import { Modal, Container } from 'react-bootstrap';
import { anyObjectProperty } from '../@types/anyObjectProperty';

type ThisModalProps = {
  title: string,
  children?: ReactNode
  rest: anyObjectProperty
}
export const ShowModal = ({title, children, ...rest}: ThisModalProps  | any) => {
  return (
    <Modal {...rest} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          {children}
        </Container>
      </Modal.Body>
    </Modal>
  );
}
