import React from 'react'
import { getRandomKey } from '../../lib/utils'

export const TableTitle = ({ titleList = [] }: { titleList: string[] }) => {
  return (
    <tr>
      {titleList?.map((item) => (
        <th key={getRandomKey()}>{item}</th>
      ))}
    </tr>
  )
}
