import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import { Login } from '../pages/Login/index'
import { Register } from '../pages/Register'
import SignInLayout from '../layout/SignInLayout'

export const SignRoutes = ({pageRef = ''}: {pageRef?: string}) => {
  return (
    <SignInLayout>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login pageRef={pageRef}/>} />
          <Route path='/signupNotForAllHi' element={<Register />} />
          <Route
            path='*'
            element={ <Navigate to="/" /> }
          />
        </Routes>
      </BrowserRouter>
    </SignInLayout>
  )
}
