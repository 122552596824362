import React from 'react'
import { useAuth } from '../context/auth'
import { useAdminPanel } from '../context/adminPanel'
import { Link, useLocation } from 'react-router-dom'

type menuLinkType = {
  name: string
  icon: string
  url: string
}

const Sidebar = () => {
  const { Logout, user } = useAuth()
  const { configData, addConfigData } = useAdminPanel()
  const location = useLocation()

  const sidebarShow = configData.sidebarShow

  const menuLinks: menuLinkType[] = [
    {
      name: 'Home',
      icon: 'fs-4 bi-house',
      url: '/',
    },
    {
      name: 'Prescriptions',
      icon: 'fs-4 bi-journal-text',
      url: '/prescriptions',
    },
  ]

  const handleLogout = () => {
    Logout()
  }

  const handleCollapse = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
    addConfigData({ sidebarShow: !sidebarShow })
  }

  return (
    <div
      className={
        'col-auto px-0 bg-dark1 b-sidebar' +
        (sidebarShow ? ' col-md-3 col-xl-2' : '')
      }
    >
      <div
        className={
          'd-flex flex-column text-white min-vh-100' +
          (sidebarShow ? ' align-items-center' : ' align-items-start')
        }
      >
        <ul
          className={
            'nav nav-pills flex-column mb-0 w-100' +
            (sidebarShow ? ' align-items-center' : ' mb-auto align-items-start')
          }
          id='menu'
        >
          <li key={'top-menu-item'} className={'w-100 mt-2'}>
            <Link
              to={'!#'}
              className={
                'nav-link align-middle px-1' +
                (sidebarShow ? 'ms-4 me-1' : ' ms-1 me-1')
              }
              onClick={handleCollapse}
            >
              <i className='fs-4 bi-list'></i>{' '}
              <span
                className={'sb-title ms-1' + (sidebarShow ? ' d-inline' : ' d-none')}
              >
                Menu
              </span>
            </Link>
          </li>

          {menuLinks.map((val: menuLinkType, index: number) => {
            return (
              <li
                key={index + 1}
                className={
                  (location.pathname === val.url ? 'active' : '') +
                  ' w-100 mt-2'
                }
              >
                <Link
                  to={val.url}
                  className={
                    'nav-link align-middle px-1' +
                    (sidebarShow ? 'ms-4 me-1' : ' ms-1 me-1')
                  }
                >
                  <i className={val.icon}></i>{' '}
                  <span
                    className={'ms-1' + (sidebarShow ? ' d-inline' : ' d-none')}
                  >
                    {val.name}
                  </span>
                </Link>
              </li>
            )
          })}
        </ul>

        <hr />
        <div className='dropdown pb-4 fixed-bottom-user'>
          <Link
            to={'!#'}
            className={
              'd-flex align-items-center text-white text-decoration-none dropdown-toggle px-1 ' +
              (sidebarShow ? ' ms-4 me-1' : ' ms-1- me-1')
            }
            id='dropdownUser1'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            <i className='fs-4 bi-person-circle'></i>{' '}
            <span className={'mx-1' + (sidebarShow ? ' d-inline' : ' d-none')}>
              {user?.userName}
            </span>
          </Link>

          <ul className='dropdown-menu dropdown-menu-dark text-small shadow'>
            <li>
              <hr className='dropdown-divider' />
            </li>
            <li>
              <Link to='!#' className='dropdown-item' onClick={handleLogout}>
                Sign out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
