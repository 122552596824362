import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { api } from '../../lib/api'
import { useAdminPanel } from '../../context/adminPanel'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { transferBackReasons } from '../../lib/declarations'
import { getRandomKey } from '../../lib/utils'
import { anyObjectProperty } from '../../@types/anyObjectProperty'
import { SubAlertErrorType } from '../../@types/authContext'


type ResponseTransfer = {
  reasonCode: number
  reasonType: string
  message: string
}

type RequestTransfer = {
  blinkRxNumber: string
  referenceId: string
  pkRx: string
  transferBackDetails: ResponseTransfer
}


const initResponseTransfer: ResponseTransfer  = {
  reasonCode: 1100,
  reasonType: 'requested_by_blink',
  message: '',
}

type TransferBackProps = {
  prescriptionId: string,
  blinkRxNumber: string,
  referenceId: string,
  pkRx: string
  onHideModel: () => void
}
export const TransferBack = ({ prescriptionId, blinkRxNumber, referenceId, pkRx, onHideModel }: TransferBackProps) => {
  const [transfer, setTransfer] = useState(initResponseTransfer)
  const [pkRxId, setPkRxId] = useState(pkRx)
  const { setNewAlert } = useAdminPanel()

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    try {
      if (typeof prescriptionId !== 'undefined') {
        const res: ResponseTransfer | any = await api.get(
          '/admin/transfer-back-details/' + prescriptionId,
          {
            method: 'get',
          },
        )

        if (res.data.hasOwnProperty('status') &&  res.data?.status > 204 ) {
          setNewAlert({
            err: res.data as SubAlertErrorType,
            show: true,
          })
        } else {
          setTransfer({ ...res.data })
        }

      }
    } catch (error) {
      // setNewAlert({
      //   err: error as AxiosError,
      //   show: true,
      // })
    }
  }

  const handleOnChange = (e: any) => {
    const newTransfer = {...transfer}

    type ObjectKey = keyof typeof transfer;

    const targetValue = e.target.value;
    const targetName = e.target.name as ObjectKey;

    setTransfer({...newTransfer, [targetName]: targetValue })

  }


  const saveHandle = async (event: React.BaseSyntheticEvent) => {
    event.preventDefault()

    const body: RequestTransfer = {
      blinkRxNumber: blinkRxNumber,
      referenceId: referenceId,
      pkRx: pkRxId,
      transferBackDetails: transfer,
    }

    const select: anyObjectProperty = {...transferBackReasons}
    body.transferBackDetails.reasonType = select[transfer.reasonCode]

    try {
      const res = await api.post('/admin/transfer-back-execute', body)
      if (res.data.hasOwnProperty('status') &&  res.data?.status > 204 ) {
        setNewAlert({
          err: res.data as SubAlertErrorType,
          show: true,
        })
      }

      // if (res.data.hasOwnProperty('status') &&  [200, 202].includes(res.data?.status) ) {
        onHideModel()
      // }

    } catch (error) {
      setNewAlert({
        err: error as AxiosError,
        show: true,
      })
    }
  }

  return (
    <>
      <Form role='form' className='mb-4' onSubmit={(e) => saveHandle(e)}>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formReasonType'>
              <Form.Label>Status</Form.Label>
              <Form.Select
                key={
                  'form-TransferBack-reasonType-select' +
                  getRandomKey().toString()
                }
                name='reasonCode'
                value={transfer.reasonCode || ''}
                onChange={handleOnChange}
                required
              >

                {Object.entries(transferBackReasons)?.map(([key, val]) => (
                  <option
                    value={key}
                    key={
                      'form-TransferBack-reasonType-select-option-' +
                      getRandomKey().toString()
                    }
                  >
                    {val}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formMessage'>
              <Form.Label>message</Form.Label>
              <Form.Control
                name='message'
                type='text'
                value={transfer.message || ''}
                onChange={handleOnChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formPkRx'>
              <Form.Label>pkRx</Form.Label>
              <Form.Control
                name='pkRx'
                value={pkRxId || ''}
                onChange={(e) => setPkRxId(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className='text-end'>
            <Button variant='success' type='submit'>
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
