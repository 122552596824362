export interface ReactSelectOption {
  value: string | any
  label: string | any
  isFixed?: boolean
  isDisabled?: boolean
}

export const carriersList = ['ups', 'usps', 'fedex', 'ups_mail_innovations', 'usps_first_class_mail'];

export const statusList = ['intake', 'dispense', 'transferBack', 'Claim Response Sent']

export const subStatusList = [
  'received',
  'fill',
  // 'verify',
  // 'batch',
  'package',
  'ship',
]
// if status == 'dispense'  && subStatus == 'ship' => color = 'green'
// if requestType == 'transfer_back' => color = 'blue'

export const requestType = [
  'dispense_request', // yellow
  'refill_dispense_request', // orange
  'refill_initiation_request', // orange
  'refill_request', // orange
  'transfer_prescription', // yellow
  'transfer_prescription_only', // red
  'transfer_back_initiate', // blue
  'transfer_back', // blue
]

export const requestTypeInfo: { [key: string]: string} = {
  'dispense_request': 'Prescription is validated by External. Prescription is ready for dispensing. No additional actions required.', // yellow
  'refill_dispense_request': 'Prescription is validated by External. Prescription is ready for dispensing. No additional actions required.', // orange
  'refill_initiation_request': 'Prescription is not validated. Please visit External portal to complete the process', // orange
  'refill_request': 'Prescription is validated by External. Prescription is ready for dispensing. No additional actions required.', // orange
  'transfer_prescription': 'Prescription is validated by External. Prescription is ready for dispensing. No additional actions required.', // yellow
  'transfer_prescription_only': 'Prescription is not validated. Please visit External portal to complete the process', // red
  'transfer_back_initiate': 'External requests this prescription back. Please select transfer back status and submit the form.', // blue
  'transfer_back': 'Prescription is transferred back to External. ', // blue
}

export const transferBackReasons = {
  1100: 'requested_by_blink',
  1101: 'requested_by_blink_manual',
  1200: 'cannot_fill',
  1201: 'cannot_fill_out_of_stock',
  1900: 'not_defined',
}

export const getStatusOption = (disableOption: string[] = []) => {
  const statusOption: ReactSelectOption[] = [{ value: '', label: '---' }]
  statusList?.map((item) => statusOption.push({ value: item, label: item, isDisabled: disableOption.indexOf(item) > -1 ? true : false }))
  return statusOption
}
export const getSubStatusOption = () => {
  const subStatusOption: ReactSelectOption[] = [{ value: '', label: '---' }]
  subStatusList?.map((item) =>
    subStatusOption.push({ value: item, label: item }),
  )
  return subStatusOption
}
export const getRequestTypeOption = () => {
  const requestTypeOption: ReactSelectOption[] = [{ value: '', label: '---' }]
  requestType?.map((item) =>
    requestTypeOption.push({ value: item, label: item }),
  )
  return requestTypeOption
}
export const getReactSelectOption = (list: string[], withOutFirst = false) => {
  const optionList: ReactSelectOption[] = [{ value: '', label: '---' }]
  list?.map((item) => optionList.push({ value: item, label: item }))
  if (withOutFirst === true) {
    optionList.shift();
  }
  return optionList
}
