import React from 'react'
import { useAuth } from '../context/auth'
import { Button, Container } from 'react-bootstrap'
import { PageTitle } from '../components/pageTitle'

export const Home = () => {
  const { Logout, user } = useAuth()
  const referrer = localStorage.getItem('referrer') || null

  if (referrer) {
    localStorage.removeItem('referrer')
    window.location.replace(referrer)
  }

  const handleLogout = () => {
    Logout()
  }

  return (
    <>
      <PageTitle>Home page</PageTitle>
      <Container fluid className='p-sm-4 '>
        <h4>Welcome {user?.userName}{' '}</h4>
        <Button
          onClick={handleLogout}
          variant='primary'
          type='button'
          className=''
        >
          Logout
        </Button>
      </Container>
    </>
  )
}
