import React from 'react'
import { Alert } from 'react-bootstrap'

type AlertProps = {
  type: string
  message: string,
  alertKey: string
}
export const ShowAlert = ({type, message, alertKey}: AlertProps) => {
  return (
    <div>
      <Alert key={alertKey} variant={type} className='alert-fixed alert-dismissible' transition={true}>
        <div className='display-line-break'>{message}</div>
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </Alert>
    </div>
  )
}
