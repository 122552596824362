import axios from "axios";
import { LoginType } from '../@types/authContext'
import { useAdminPanel } from '../context/adminPanel';
import env from "react-dotenv";

export const BASE_URL_DEV_2 = "http://3.213.212.184:5000" //dev
export const BASE_URL_DEV = "http://52.20.154.157:5000" //dev
// export const BASE_URL_PROD = "http://34.234.179.67:5000" //prod
export const BASE_URL_PROD = "https://portal.pharmacyinnovations.net" //prod

// export const BASE_URL = env.NODE_ENV === 'development' ? BASE_URL_DEV : BASE_URL_PROD ;
let BASE_URL_sel = BASE_URL_PROD
if (env.NODE_ENV === 'development') {
  BASE_URL_sel = BASE_URL_DEV;
}
if (env.NODE_ENV === 'development2') {
  BASE_URL_sel = BASE_URL_DEV_2;
}

export const BASE_URL = BASE_URL_sel;

export const API_URL = `${BASE_URL}/api`

export type ResObjectType = {config: {}, data: LoginType, headers: {}, request: {}, status: number, statusText: string};

export const api = axios.create({
  baseURL: API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    'Access-Control-Allow-Origin': '*',
    'Accept': 'text/plain'
  },
});

api.interceptors.request.use(
  async (config: any) => {
    const token = localStorage.getItem("@Auth:access_token");
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    const { setNewAlert } = useAdminPanel();
    setNewAlert({
      err: error,
      show: true
    })
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {

    return Promise.reject(error);
  }
);
