import React, { createContext, useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { getRandomKey } from '../lib/utils'
import {
  AdminPanelContextData,
  AlertErrorType,
  SubAlertErrorType,
} from '../@types/authContext'
import { anyObjectProperty } from '../@types/anyObjectProperty'

const AdminPanelContext = createContext<AdminPanelContextData>(
  {} as AdminPanelContextData,
)

export const AdminPanelProvider = ({ children }: any) => {
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false)
  const [alertData, setAlertData] = useState({
    type: 'danger',
    message: 'Something goes wrong',
    alertKey: getRandomKey().toString(),
  })

  const [configData, setConfigData] = useState<anyObjectProperty>({
    sidebarShow: false,
    windowSize: {}
  })

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth <= 576) {
        addConfigData({sidebarShow: false, windowSize: {w: window.innerWidth, h: window.innerHeight}})
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function loadStorageData() {
      setIsShowAlert(false)
    }

    loadStorageData()
  }, [])


  const addConfigData = (conf: anyObjectProperty) => {

    setConfigData({...configData, ...conf})
  }

  const setNewAlert = async ({ err, show }: AlertErrorType) => {
    const error = err as AxiosError
    let message = 'Something goes wrong'
    let variant = 'danger'

    const alertKey = getRandomKey().toString()

    if (error.response) {
      // The client was given an error response (5xx, 4xx)
      // console.log(error.response.data)
      // console.log(error.response.status)
      // console.log(error.response.headers)
      message = `${error.response.status} - ${error.response.statusText}`

      const body: any = error.response.data
      message =
        (body.status || error.response.status) +
        ' - ' +
        (body.title || error.response.statusText);

      if (body?.message){
        message += '\n\n' + (body.message.toString())
      }

      let detailError = null;
      if (body?.details) {

        try {
          detailError = JSON.parse(body.details);
          detailError = detailError.errors as Array<any>
          if (typeof detailError === 'object' && !Array.isArray(detailError) ) {
            Object.entries(detailError).map(([key, val]: any) => {
              message += '\n\n' + JSON.stringify(val);
              return null
            })
          }
          if (typeof detailError === 'object' && Array.isArray(detailError)){
            detailError.map((val: any) => {
              message += '\n\n' + val.error_type + ' - ' + val.message;
              return null
            })
          }
        } catch (error) {
          // null
        }
      }

      if (body?.errors) {
        Object.entries(body.errors).map(([key, val]: any) => {
          message += '\n\n' + val[0]
          return null
        })

        if (error.response.status >= 400) {
          message = error.response.status + ' - ' + (body.message || message)
        }
      }
    } else if (error.request) {
      // The client never received a response, and the request was never left
      message += ' - timeout'
    } else if (error.message ) {
      const error = err as SubAlertErrorType
      message = `${error.message} ${
        (error.title as string) || ''
      }`
    }

    setAlertData({
      type: variant,
      message: message,
      alertKey: alertKey,
    })
    setIsShowAlert(show)

    setTimeout(() => {
      setIsShowAlert(false)
    }, 5000)
  }

  return (
    <AdminPanelContext.Provider value={{ isShowAlert, alertData, setNewAlert, configData, addConfigData}}>
      {children}
    </AdminPanelContext.Provider>
  )
}

export const useAdminPanel = () => {
  return useContext(AdminPanelContext)
}
