import React, { useEffect, useState } from 'react'
import { anyObjectProperty } from '../../@types/anyObjectProperty'
import { ActionBtn } from '../../components/columType'
import { Button, Form, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap'
import { RowInfo } from './rowInfo'
import { formatDate } from '../../lib/utils'
import {
  ReactSelectOption,
  getReactSelectOption,
  getStatusOption,
  requestType,
  requestTypeInfo,
  statusList,
  subStatusList,
} from '../../lib/declarations'
import { ShowModal } from '../../components/showModal'
import { TransferBack } from './transferBack'
import { ShippingDetails } from './shippingDetails'
import { useAdminPanel } from '../../context/adminPanel'
import { AxiosError } from 'axios'
import { api } from '../../lib/api'
import { SubAlertErrorType } from '../../@types/authContext'
import Select from 'react-select'
import './rowInfoStyle.scss'

type RowProps = {
  item: anyObjectProperty
  // onSaveHandle: (row: anyObjectProperty) => void
}
export const RowItem = ({ item }: RowProps) => {
  const [rowItem, setRowItem] = useState<anyObjectProperty>(item)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [showStatusModal, setShowStatusModal] = useState<Number>(0)
  const { setNewAlert } = useAdminPanel()
  const [subStatusItems, setSubStatusItems] = useState(subStatusList)
  const [disabledField, setDisabledField] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  const [selectedSubstatusOption, setSelectedSubstatusOption] = useState<any>({
    value: item.substatus || '',
    label: item.substatus || '',
  })
  // Initializing didMount as false
  const [didMount, setDidMount] = useState(false)
  const [bgColorStatus, setBgColorStatus] = useState('bg-clear')

  const initStatusSelect = () => {
    if (
      rowItem.status === statusList[2] ||
      rowItem.requestType === 'transfer_back'
    ) {
      // 'transferBack'
      setDisabledField(true)
      setSelectedSubstatusOption(null)
    } else {
      setDisabledField(false)
    }
    if (rowItem.status === statusList[0]) {
      // 'intake'
      setSubStatusItems([subStatusList[0]])
    }
    if (rowItem.status === statusList[1]) {
      // 'dispense'
      let subStatusNew = [...subStatusList];
      subStatusNew.shift();
      setSubStatusItems([...subStatusNew]);
    }
  }

  // Setting didMount to true upon mounting
  useEffect(() => {
    setDidMount(true)

    initStatusSelect();

    let bgStatus = bgColorStatus
    if (
      rowItem.requestType === requestType[0] ||
      rowItem.requestType === requestType[4]
    ) {
      bgStatus = 'bg-yellow'
    }
    if (rowItem.requestType.indexOf('refill_') === 0) {
      bgStatus = 'bg-orange'
    }
    if (rowItem.requestType === requestType[5]) {
      bgStatus = 'bg-red'
    }
    if (
      rowItem.requestType === requestType[6] ||
      rowItem.requestType === requestType[7]
    ) {
      bgStatus = 'bg-blue'
    }
    if (
      rowItem.status === statusList[1] &&
      rowItem.substatus === subStatusList[3] &&
      rowItem.requestType !== requestType[7]
    ) {
      bgStatus = 'bg-green'
    }
    setBgColorStatus(bgStatus)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (didMount) {
        const row = { ...rowItem }

        initStatusSelect();

        row.substatus = ''
        setRowItem({ ...row })
        setSelectedSubstatusOption(null)
      }
    },
    // eslint-disable-next-line
    [rowItem.status],
  )

  const todoOnChange = async (targetName: string, targetValue: string | '') => {
    const row = { ...rowItem }
    row[targetName] = targetValue

    if (row.substatus === null) {
      setSelectedSubstatusOption(null)
    } else {
      setSelectedSubstatusOption({ value: row.substatus, label: row.substatus })
    }

    if (targetValue === statusList[2]) {
      // 'transferBack'
      setShowStatusModal(1)
      setIsEdit(false)
    } else if (['ship'].indexOf(targetValue) > -1) {
      setShowStatusModal(2)
    }

    setRowItem({ ...row })
    if (!isEdit && targetValue !== statusList[2]) {
      setIsEdit(true)
    }
  }

  const onChangeHandle = async (e: React.BaseSyntheticEvent) => {
    const targetValue = e.target.value
    const targetName = e.target.name

    await todoOnChange(targetName, targetValue)
  }

  const reactSelectOnChange = async (
    option: ReactSelectOption | null,
    targetName: string,
  ) => {
    const targetValue = option?.value || ''
    await todoOnChange(targetName, targetValue)
  }

  const onRowInfo = () => {
    setShowInfo(!showInfo)
  }

  const onSaveRow = async (shippingDetails?: anyObjectProperty) => {
    const row = { ...rowItem }

    let data: anyObjectProperty = {
      status: row.status,
      substatus: row.substatus,
      blinkRxNumber: row.blinkRxNumber,
      pkRx: row.pkRx,
    }

    if (typeof shippingDetails !== 'undefined') {
      data.shippingDetails = shippingDetails
    }
    let toDoUpdate = false;

    if ( [subStatusList[3]].indexOf(data.substatus) > -1  && typeof shippingDetails === 'undefined'  ){
      try {
        if (typeof rowItem.id !== 'undefined') {
          const res: any = await api.get(
            '/admin/shipping-details/' + rowItem.id,
            {
              method: 'get',
            },
          )

          if (res.data.hasOwnProperty('status') &&  res.data?.status > 204 ) {
            setShowStatusModal(2);
            return;
          } else {
            data.shippingDetails = res.data
            toDoUpdate = true;
          }
        }
      } catch (error) {
        // null
        setShowStatusModal(2);
      }
    } else {
      toDoUpdate = true;
    }

    if (toDoUpdate) {
      try {
        const res = await api.post('/admin/status-update', data)

        if (res.data.hasOwnProperty('errors')) {
          setNewAlert({
            err: res.data as SubAlertErrorType,
            show: true,
          })
        } else {
          if ([200,201,202].indexOf(res.status) > -1  && showStatusModal !== 0){
            setShowStatusModal(0);
          }
          setIsEdit(false)
        }
      } catch (error) {
        setNewAlert({
          err: error as AxiosError,
          show: true,
        })
      }
    }


  }

  return (
    <>
      <tr className='table-row'>
        <td onClick={onRowInfo} className={bgColorStatus + ' border-left'}>
          <Stack direction='horizontal' gap={1}>
            <div className={'status-color-point ' + bgColorStatus}></div>
            {/* <Badge bg="success">{' '}</Badge> */}
            {rowItem.blinkRxNumber}
          </Stack>
        </td>
        <td onClick={onRowInfo}>
          {(rowItem.patient?.firstName || '') +
            ' ' +
            (rowItem.patient?.lastName || '')}
        </td>
        <td onClick={onRowInfo}>{formatDate(rowItem.createdDate)}</td>
        <td onClick={onRowInfo}>{formatDate(rowItem.needsByDate)}</td>
        <td onClick={onRowInfo}>
        <span className='float-start_1'>{rowItem.requestType}</span>{' '}
          <OverlayTrigger
            key={"getRandomKey-"+ rowItem.id}
            placement="top"
            delay={{ show: 100, hide: 100 }}
            overlay={
              <Tooltip id={`tooltip-getRandomKey-${rowItem.id}`}>
                { requestTypeInfo[rowItem.requestType] }
              </Tooltip>
            }
          >
            <i className='fs-5 bi-info-circle float-end me-2-'></i>
          </OverlayTrigger>
        </td>
        <td>
          <Select
            key={'key_status_'+rowItem.id}
            className='basic-select-single'
            classNamePrefix='select'
            // defaultValue={null}
            // defaultValue={{ value: rowItem.status, label: rowItem.status }}
            value={
              disabledField
                ? selectedSubstatusOption
                : { value: rowItem.status || '', label: rowItem.status || '' }
            }
            isDisabled={disabledField}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            name='status'
            options={ [requestType[2], requestType[5]].indexOf(rowItem.requestType) > -1 ? getStatusOption([statusList[1]]) : getStatusOption()}
            onChange={(option: ReactSelectOption | null) =>
              reactSelectOnChange(option, 'status')
            }
          />
        </td>
        <td>
          <Select
            key={'key_substatus_'+rowItem.id}
            className='basic-select-single'
            classNamePrefix='select'
            // defaultValue={selectedSubstatusOption}
            // value={{ value: rowItem.substatus, label: rowItem.substatus }}
            value={selectedSubstatusOption}
            isDisabled={disabledField}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            name='substatus'
            options={getReactSelectOption(subStatusItems)}
            onChange={(option: ReactSelectOption | null) =>
              reactSelectOnChange(option, 'substatus')
            }
          />
        </td>
        <td>
          <Form.Control
            key={'key_pkRx_'+rowItem.id}
            name='pkRx'
            value={disabledField ? '' : rowItem.pkRx || ''}
            onChange={onChangeHandle}
            disabled={disabledField}
          />
        </td>
        <td>
          {isEdit ? (
            <>
              <Button variant={'success'} onClick={(e) => onSaveRow()}>
                {'save'}
              </Button>{' '}
            </>
          ) : (
            <ActionBtn
              colId={rowItem.id}
              type='primary'
              title={
                showInfo ? (
                  <i className='fs-6 bi-chevron-up'></i>
                ) : (
                  <i className='fs-6 bi-chevron-down'></i>
                )
              }
              onClickHandle={onRowInfo}
              data-bs-toggle='collapse'
              data-bs-target={'#r-' + rowItem.id}
              data-bs-parent='.table'
            />
          )}
        </td>
      </tr>
      <tr
        className={'collapse accordion-collapse' + (showInfo ? ' show' : '')}
        id={'r-' + rowItem.id}
      >
        <td colSpan={9}>
          <RowInfo
            item={rowItem}
            onCloseHandle={onRowInfo}
            data-bs-parent='.table'
          />
        </td>
      </tr>

      <ShowModal
        show={showStatusModal === 1}
        title='Transfer Back'
        onHide={() => {
          setShowStatusModal(0);
          setDisabledField(false);
        }}
      >
        <TransferBack
          prescriptionId={rowItem.id}
          blinkRxNumber={rowItem.blinkRxNumber}
          referenceId={rowItem.referenceId}
          pkRx={rowItem.pkRx || ''}
          onHideModel={() => {
            setShowStatusModal(0)
            setDisabledField(true);
          }}
        />
      </ShowModal>
      <ShowModal
        show={showStatusModal === 2}
        title='Shipping Details'
        onHide={() => setShowStatusModal(0)}
      >
        <ShippingDetails
          prescriptionId={rowItem.id}
          shippingSaveHandle={onSaveRow}
        />
      </ShowModal>
    </>
  )
}
