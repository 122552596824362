export const getRandomKey = () => Math.floor(Math.random() * 10000000) + 1

export const formatDate = (d: string) => {

  if (d.length  === 0) {
    return d;
  }
  const dateList = d.split(',');

  let dateRes: string[] = [];

  for (let index = 0; index < dateList.length; index++) {
    let date: any = new Date(dateList[index]);

    if (!dateList[index].includes('T')) {
      const modifyDate: string = dateList[index]+'T00:00:00';
      date = new Date(modifyDate);
    }

    if (!isNaN(date) === false) {
      dateRes.push(dateList[index]);
    }
    dateRes.push(date.toDateString());

  }

  return dateRes.join(', ');

}

export const toSentenceCase = (camelCase: string) => {
  if (camelCase) {
    const result = camelCase.replace(/([A-Z])/g, ' $1')
    // return result[0].toUpperCase() + result.substring(1).toLowerCase()
    return result[0].toUpperCase() + result.substring(1)
  }
  return ''
}
