import React, { useEffect, useState } from 'react'
import { anyObjectProperty } from '../../@types/anyObjectProperty'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import {
  ReactSelectOption,
  getReactSelectOption,
  getRequestTypeOption,
  getStatusOption,
  getSubStatusOption,
} from '../../lib/declarations'
import Select, { ActionMeta, OnChangeValue } from 'react-select'
import { api } from '../../lib/api'
import { AxiosError } from 'axios'
import { useAdminPanel } from '../../context/adminPanel'

type SearchFormProps = {
  formValue: anyObjectProperty
  // searchHandle: (e: ReactEventHandler) => void
  searchHandle: (e: React.BaseSyntheticEvent) => void
}

export const SearchForm = ({ formValue, searchHandle }: SearchFormProps) => {
  let [localFormValue, setLocalFormValue] = useState(formValue)
  const { setNewAlert } = useAdminPanel()
  const [viewSpinner, setViewSpinner] = useState(false)
  const [vendors, setVendors] = useState([])

  let statusOptionList = getStatusOption()
  // statusOptionList.pop()
  statusOptionList = statusOptionList.slice(0,3);

  useEffect(() => {
    const getVendorData = async () => await getVendorList();
    getVendorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getVendorList = async () => {
    try {
      await api.get('/admin/vendors').then((response) => {
        setVendors(response.data);
      });
      const newSearchProp = { ...formValue };
      setLocalFormValue({ ...newSearchProp });

    } catch (error) {
      setNewAlert({
        err: error as AxiosError,
        show: true,
      })
    }
  }

  const handleOnChange = (e: any) => {
    const newSearchProp = { ...localFormValue }

    const targetValue = e.target.value.trim()
    const targetName = e.target.name.trim()

    newSearchProp[targetName] = targetValue
    setLocalFormValue({ ...newSearchProp })
  }

  const reactSelectOnChange = (
    option: ReactSelectOption | null,
    targetName: string,
  ) => {
    const newSearchProp = { ...localFormValue }
    newSearchProp[targetName] = option?.value || ''
    setLocalFormValue({ ...newSearchProp })
  }

  const reactSelectOnChangeMulti = (
    newValue: OnChangeValue<ReactSelectOption, true>,
    actionMeta: ActionMeta<ReactSelectOption>
  ) => {
    const newSearchProp = { ...localFormValue }
    const setNewVal = [];
    for( let i = 0; i < newValue.length; i++){
      setNewVal.push(newValue[i].value)
    }

    newSearchProp[actionMeta.name as keyof SearchFormProps] = setNewVal;

    setLocalFormValue({ ...newSearchProp });

  }

  const handleOnClickExportToCsv = async () => {
    setViewSpinner(true)
    try {
      await api
        .get('/admin/prescriptions-as-csv', { params: localFormValue })
        .then((response) => {
          const fileName = 'Prescriptions.csv'
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          setTimeout(() => {
            setViewSpinner(false)
          }, 50)
        })
    } catch (error) {
      setTimeout(() => {
        setViewSpinner(false)
      }, 50)

      setNewAlert({
        err: error as AxiosError,
        show: true,
      })
    }
  }

  const listSelected = (): ReactSelectOption[] => {
    // getRequestTypeOption().filter(l => localFormValue.requestTypes.includes(l.label));
    const listFull = getRequestTypeOption();
    const listDef = listFull.filter( l => localFormValue.requestTypes.includes(l.label) );
    return listDef;
  }

  return (
    <>
      <Form
        role='form'
        className='mb-5 f-search'
        onSubmit={(e) => searchHandle(e)}
      >
        <Row>
          {/* <Col xs={12} sm={6} md={4}> */}

          <Col xs={6} md={4}>
            <Form.Group className='mb-3' controlId='formBlinkRxNumber'>
              <Form.Label className='mb-0'>External Rx Number</Form.Label>
              <Form.Control
                name='blinkRxNumber'
                type='text'
                // placeholder='pharmacy Npi'
                value={localFormValue.blinkRxNumber || ''}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Col>

          <Col xs={6} md={4}>
            <Form.Group className='mb-3' controlId='formStatus'>
              <Form.Label className='mb-0'>Status</Form.Label>
              <Select
                key='keyFormStatus'
                className='basic-select-single'
                classNamePrefix='select'
                defaultValue={{
                  value: localFormValue.status,
                  label: localFormValue.status,
                }}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                name='status'
                options={statusOptionList}
                onChange={(option: ReactSelectOption | null) =>
                  reactSelectOnChange(option, 'status')
                }
              />
            </Form.Group>
          </Col>
          <Col xs={6} md={4}>
            <Form.Group className='mb-3' controlId='formSubStatus'>
              <Form.Label className='mb-0'>Sub Status</Form.Label>
              <Select
                key='keyFormSubStatus'
                className='basic-select-single'
                classNamePrefix='select'
                defaultValue={{
                  value: localFormValue.substatus,
                  label: localFormValue.substatus,
                }}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                name='substatus'
                options={getSubStatusOption()}
                onChange={(option: ReactSelectOption | null) =>
                  reactSelectOnChange(option, 'substatus')
                }
              />
            </Form.Group>
          </Col>
          {/* </Row>

        <Row> */}
          <Col xs={6} md={4}>
            <Form.Group className='mb-3' controlId='formRequestType'>
              <Form.Label className='mb-0'>Request Type</Form.Label>
              <Select
                isMulti={true}
                key='keyFormRequestType'
                className='basic-select-single'
                classNamePrefix='select'
                // defaultValue={getRequestTypeOption().filter(l => localFormValue.requestTypes.includes(l.label))}
                defaultValue={listSelected() as ReactSelectOption[]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={false}
                name='requestTypes'
                options={getRequestTypeOption()}
                // onChange={(option: ReactSelectOption | null) =>
                //   reactSelectOnChange(option, 'requestTypes')
                // }
                onChange={reactSelectOnChangeMulti}
              />
            </Form.Group>
          </Col>
          <Col xs={6} md={4}>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label className='mb-0'>Pharmacy Npi</Form.Label>
              <Form.Control
                name='pharmacyNpi'
                type='text'
                // placeholder='pharmacy Npi'
                value={localFormValue.pharmacyNpi || ''}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Col>
          <Col xs={6} md={4}>
            <Form.Group className='mb-3' controlId='formVendor'>
              <Form.Label className='mb-0'>Vendor</Form.Label>
              <Select
                key='keyFormVendor'
                className='basic-select-single'
                classNamePrefix='select'
                defaultValue={{
                  value: localFormValue.vendor || '',
                  label: localFormValue.vendor || '',
                }}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                name='vendor'
                options={getReactSelectOption(vendors)}
                onChange={(option: ReactSelectOption | null) =>
                  reactSelectOnChange(option, 'vendor')
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={8}>
           <Form.Group className='mb-3' controlId='formPatientName'>
              <Form.Label className='mb-0'>Patient name</Form.Label>
              <Form.Control
                name='patientName'
                type='text'
                // placeholder='pharmacy Npi'
                value={localFormValue.patientName || ''}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Col>
          <Col xs={6} md={4} className='d-flex align-items-center justify-content-end'>
            <Button variant='primary' type='submit' className='px-4'>
              Search
            </Button>
            <Button
              variant='success'
              type='button'
              className='ms-2'
              onClick={handleOnClickExportToCsv}
            >
              {viewSpinner ? (
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                'CSV'
              )}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col></Col>
      </Row>
    </>
  )
}
