import React from 'react'
import { anyObjectProperty } from '../../@types/anyObjectProperty'
import { Accordion, Col, Row } from 'react-bootstrap'
import { formatDate, getRandomKey, toSentenceCase } from '../../lib/utils'
import './rowInfoStyle.scss'

type RowInfoProps = {
  item: anyObjectProperty
  onCloseHandle: () => void
}

const modifyFieldValue = (key: string, val: any) => {
  let modifyVal = val != null ? val : ' '
  if (key.includes('Date') || key.includes('date')) {
    modifyVal = formatDate(modifyVal)
  }
  return modifyVal
}

const replaceLabel = (key: string): string => {
  let result: string = key.replace(/blink/, 'external')
  if( key === 'remainingRefills') {
    result = key.replace(/remainingRefills/, 'remainingFills')
  }
  return result;
}

let rowId = 0;

// type elemType = [string, string | anyObjectProperty]
export const RowInfo = ({ item, onCloseHandle }: RowInfoProps) => {
  rowId = item.id;
  const tree = { ...item }
  delete tree.id

  let cardTitle = 'General'

  return (
    <Accordion className='small1' alwaysOpen={false}>
      <ViewAccordion
        key={'keyGeneral'+rowId}
        node={tree}
        title={cardTitle}
        withSubNode={false}
      />
      {Object.entries(tree).map(([key, val]: any, index: number) => {
        if (val != null) {
          if (typeof val === 'object' && Object.keys(val).length > 0) {
            return (
              <ViewAccordion
                key={'key_'+key+rowId}
                node={val}
                title={key !== 'medications'? key : 'Current '+toSentenceCase(key) }
                withSubNode={true}
              />
            )
          }
        }
        return null
      })}
    </Accordion>
  )
}

const ViewAccordion = ({
  node,
  title,
  withSubNode = false,
}: {
  node: {}
  title: string
  withSubNode: boolean
}) => {
  return (
    <Accordion.Item
      eventKey={'key_acc_'+title+rowId}
      key={'key_acc_'+title+rowId}
      className='mb-1'
    >
      <Accordion.Header>{toSentenceCase(title)}</Accordion.Header>
      <Accordion.Body>
        <>
          <ViewAccordionBody
            node={node}
            withSubNode={false}
            key={'key_acc_body_'+title+'_'+rowId}
          />
          {withSubNode && <ViewBodyRecursive node={node} level={1} />}
        </>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const ViewAccordionBody = ({
  node,
  withSubNode = false,
}: {
  node: {}
  withSubNode: boolean
}) => {
  return (
    <Row key={getRandomKey()}>
      {Object.entries(node).map(([key, val]: any, index: number) => {
        if (val != null) {
          if (typeof val !== 'object' && !Array.isArray(val)) {
            return (
              <Col sm={6} key={getRandomKey()}>
                <strong>{replaceLabel(key)}</strong>: {modifyFieldValue(key, val)}
              </Col>
            )
          }
        } else {
          return (
            <Col sm={6} key={getRandomKey()}>
              <strong>{replaceLabel(key)}</strong>:{' '}
            </Col>
          )
        }
        return null
      })}
      {withSubNode && <ViewBodyRecursive node={node} level={2} />}
    </Row>
  )
}

const ViewBodyRecursive = ({
  node,
  level = 1,
}: {
  node: {}
  level: number
}) => {
  return (
    <>
      {Object.entries(node).map(([key, val]: any, index: number) => {
        if (val != null) {
          if (typeof val === 'object' && !Array.isArray(val)) {
            return (
              <span key={getRandomKey()}>
                {!(key === 'address' && Number(level) >= 2) ? (
                  <h5 className={'mt-3 mb-2 gray-dark level-' + level}>
                    {replaceLabel(toSentenceCase(key))}
                  </h5>
                ) : null}

                <ViewAccordionBody
                  node={val}
                  withSubNode={true}
                  key={getRandomKey()}
                />
              </span>
            )
          }
          if (Array.isArray(val) && val.length > 0) {
            return val.map((obj: any) => {
              return (
                <span key={getRandomKey()}>
                  {!(key === 'address' && Number(level) >= 2) ? (
                    <h5 className={'mt-3 mb-2 gray-dark level-' + level}>
                      {replaceLabel(toSentenceCase(key))}
                    </h5>
                  ) : null}

                  <ViewAccordionBody
                    node={obj}
                    withSubNode={true}
                    key={getRandomKey()}
                  />
                </span>
              )
            })
          }
        }
        return null
      })}
    </>
  )
}
