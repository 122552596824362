import React, { ReactNode } from 'react'
import { Container } from 'react-bootstrap'
import './adminStyle.scss'
import Sidebar from '../components/Sidebar'
import { useAdminPanel } from '../context/adminPanel'
import { ShowAlert } from '../components/showAlert'

type LayoutProps = {
  children: ReactNode
}

const AdminLayout = ({ children }: LayoutProps) => {
  const { isShowAlert, alertData } = useAdminPanel()

  return (
    <div className='container-fluid'>
      <div className='row flex-nowrap'>
        <Sidebar />
        <div className='col p-0'>
          <Container fluid className='p-0'>
            {children}
          </Container>
        </div>
      </div>
      {isShowAlert ? (
        <ShowAlert
          alertKey={alertData?.alertKey || 'show-alert-key'}
          type={alertData?.type || 'info'}
          message={alertData?.message || 'Something goes wrong'}
        />
      ) : null}
    </div>
  )
}

export default AdminLayout
