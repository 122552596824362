import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import { Home } from '../pages/Home'
import { Prescriptions } from '../pages/Prescriptions'
import AdminLayout from '../layout/AdminLayout'
import { AdminPanelProvider } from '../context/adminPanel'

export const ProtectRoutes = () => {
  return (
    <AdminPanelProvider>
      <BrowserRouter>
        <AdminLayout>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/prescriptions' element={<Prescriptions />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </AdminLayout>
      </BrowserRouter>
    </AdminPanelProvider>
  )
}
