import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { useAuth } from '../context/auth'

type FormProps = {
  setUserName: (name: string) => void
  setPassword: (pass: string) => void
  handleLogin: () => void
  btnTitle: string
}

type FieldsListType = {
  userName: number
  password: number
}

export const LoginForm = ({
  setUserName,
  setPassword,
  handleLogin,
  btnTitle,
}: FormProps) => {
  const { formValidated } = useAuth()
  const [validated, setValidated] = useState(false)
  const [doSubmit, setDoSubmit] = useState(false)
  const [fieldsList, setFieldsList] = useState<FieldsListType>({
    userName: 0,
    password: 0,
  })

  const fieldsLength: FieldsListType = {
    userName: 3,
    password: 3,
  }

  useEffect(
    () => {
      if (Object.keys(formValidated).length > 0) {
        setValidated(true)
      }
    },
    // eslint-disable-next-line
    [validated, formValidated],
  )

  const InnerValidation = (event: React.BaseSyntheticEvent) => {
    const fList: FieldsListType = { ...fieldsList }

    fList[event.target.name as keyof FieldsListType] = event.target.value.length

    let isAllowedSubmit = true
    Object.entries(fList).forEach(([key, val]) => {
      if (val < fieldsLength[key as keyof FieldsListType]) {
        isAllowedSubmit = false
      }
    })

    setFieldsList({ ...fList })

    if (isAllowedSubmit) {
      setDoSubmit(true)
    } else {
      setDoSubmit(false)
    }
  }

  return (
    <Form role='form' validated={validated}>
      <Form.Group className='mb-3' controlId='formBasicUserName'>
        <Form.Label className='form-label-view'>User name</Form.Label>

        <InputGroup className='mb-3'>
          <InputGroup.Text id='basic-addon1'>
            <i className='bi bi-person opacity-50'></i>
          </InputGroup.Text>
          <Form.Control
            required
            name='userName'
            type='text'
            placeholder='User name'
            onKeyUp={InnerValidation}
            onChange={(e) => {
              setUserName(e.target.value)
            }}
            className='form-input-view'
          />
        </InputGroup>

        <Form.Control.Feedback type='invalid' className='display-line-break'>
          {formValidated.UserName?.join(' \n')}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className='mb-3' controlId='formBasicPassword'>
        <Form.Label className='form-label-view'>Password</Form.Label>
        <InputGroup className='mb-3'>
          <InputGroup.Text id='basic-addon1'>
            <i className='bi bi-lock opacity-50'></i>
          </InputGroup.Text>
          <Form.Control
            required
            name='password'
            type='password'
            placeholder='Password'
            onKeyUp={InnerValidation}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </InputGroup>
        <Form.Control.Feedback type='invalid' className='display-line-break'>
          {formValidated.Password?.join(' \n')}
        </Form.Control.Feedback>
      </Form.Group>

      <div className='d-grid mt-5'>
        <Button
          variant='primary'
          type='submit'
          onClick={handleLogin}
          disabled={!doSubmit}
        >
          {btnTitle}
        </Button>
      </div>
    </Form>
  )
}
