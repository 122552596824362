import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthContextData, LoginType } from '../@types/authContext'
import { api, ResObjectType } from '../lib/api'
import { AxiosError } from 'axios'

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<LoginType | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [resError, setResError] = useState({
    message: '',
    status: 200,
    isShow: false,
  })
  const [formValidated, setFormValidated] = useState({})
  const [referrer, setReferrer] = useState<Array<string>>([])


  useEffect(() => {
    async function loadStorageData() {
      const storageUser = localStorage.getItem('@Auth:user')
      const storageToken = localStorage.getItem('@Auth:access_token')

      if (storageUser && storageToken) {
        setUser(JSON.parse(storageUser))
      }
      setLoading(false)
    }

    loadStorageData()
  }, [])

  const Login = async ({ userName, password }: LoginType) => {
    setLoading(true)
    try {
      const res: ResObjectType | any = await api.post<LoginType>(
        '/Users/Login',
        {
          userName: userName,
          password: password,
        },
        { method: 'post' },
      )

      localStorage.setItem('@Auth:access_token', res.data?.token || '')
      localStorage.setItem('@Auth:user', JSON.stringify({ ...res.data }))

      setUser({ ...res.data })

      // return <Link to='/'/>
    } catch (error) {
      setError(error as AxiosError)
    }
    setLoading(false)
  }

  const RegisterUser = async ({ userName, password }: LoginType) => {
    setLoading(true)
    try {
      const res: ResObjectType | any = await api.post<LoginType>(
        '/Users/Register',
        {
          userName: userName,
          password: password,
        },
      )

      if (res.data?.token !== null && res.data?.token !== '') {
        localStorage.setItem('@Auth:access_token', res.data?.token || '')
        localStorage.setItem('@Auth:user', JSON.stringify({ ...res.data }))
        setUser({ ...res.data })
      } else {
        // window.location
        Login({ userName, password })
      }

    } catch (error) {
      setError(error as AxiosError)
    }
    setLoading(false)
  }

  const Logout = () => {
    localStorage.removeItem('@Auth:access_token')
    localStorage.removeItem('@Auth:user')
    // localStorage.clear()
    setUser(null)
  }

  const setError = (error: AxiosError) => {
    const err = error as AxiosError

    let data = { ...resError }
    if (err.response) {
      const body: any = err.response.data
      data.status = body.status || err.response.status
      data.message =
        data.status + ' - ' + (body.title || err.response.statusText)
      data.isShow = true

      if (body.errors) {
        Object.entries(body.errors).map(([key, val]: any) => {
          data.message += '\n' + val[0]
          return null
        })

        setFormValidated({ ...body.errors })
      }
      if (err.response.status >= 400) {
        data.message = err.response.status + ' - ' + (body.message || data.message)
      }
    }
    setResError({ ...data })
    setTimeout(() => {
      setResError({ ...data, isShow: false })
    }, 10000)
  }

  const saveReferrer = (location: string[]) => {
    const set = new Set(location);
    const history = Array.from(set);

    setReferrer(history)
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user?.token,
        user,
        loading,
        Login,
        Logout,
        RegisterUser,
        resError,
        formValidated,
        referrer,
        saveReferrer,
      }}
      // value={{ signed: true, user, loading, Login, Logout, RegisterUser }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
