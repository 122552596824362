import { useEffect, useState } from 'react'
import { api } from '../../lib/api'
import { useAdminPanel } from '../../context/adminPanel'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { SubAlertErrorType } from '../../@types/authContext'
import Select from 'react-select'
import { ReactSelectOption, carriersList, getReactSelectOption } from '../../lib/declarations'

type RequestShipAddress = {
  address1: string
  address2: string
  city: string
  state: string
  zipCode: string
}
type RequestShipping = {
  trackingNumber: string
  carrier: string
  address: RequestShipAddress
}

type ShippingDetailsProps = {
  prescriptionId: string
  shippingSaveHandle: (shipping: RequestShipping) => void
}

const initShippingDetails = {
  trackingNumber: '',
  carrier: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
  },
}

export const ShippingDetails = ({
  prescriptionId,
  shippingSaveHandle,
}: ShippingDetailsProps) => {
  const [shipping, setShipping] = useState(initShippingDetails)
  const { setNewAlert } = useAdminPanel()

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    try {
      if (typeof prescriptionId !== 'undefined') {
        const res: RequestShipping | any = await api.get(
          '/admin/shipping-details/' + prescriptionId,
          {
            method: 'get',
          },
        )

        if (res.data.hasOwnProperty('status') &&  res.data?.status > 204 ) {
          setNewAlert({
            err: res.data as SubAlertErrorType,
            show: true,
          })
        }

        setShipping({ ...res.data })
      }
    } catch (error) {
      // setNewAlert({
      //   err: error as AxiosError,
      //   show: true,
      // })
    }
  }

  const reactSelectOnChange = async (
    option: ReactSelectOption | null,
    targetName: string,
  ) => {
    const newShipping = { ...shipping }
    const targetValue = option?.value || ''
    setShipping({ ...newShipping, [targetName]: targetValue })
  }

  const handleOnChange = (e: any) => {
    const newShipping = { ...shipping }
    type ObjectKey = keyof typeof shipping

    const targetValue = e.target.value
    const targetName = e.target.name as ObjectKey

    setShipping({ ...newShipping, [targetName]: targetValue })
  }

  const handleOnChangeAddress = (e: any) => {
    const address = { ...shipping.address }
    type ObjectKey = keyof typeof shipping.address

    const targetValue = e.target.value
    const targetName = e.target.name as ObjectKey

    address[targetName] = targetValue

    setShipping({ ...shipping, address: address })
  }

  const saveHandle = async (event: React.BaseSyntheticEvent) => {
    event.preventDefault()

    shippingSaveHandle(shipping)
  }

  return (
    <>
      <Form role='form' className='mb-4' onSubmit={(e) => saveHandle(e)}>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formTrackingNumber'>
              <Form.Label>Tracking Number</Form.Label>
              <Form.Control
                name='trackingNumber'
                type='text'
                value={shipping.trackingNumber || ''}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formCarrier'>
              <Form.Label>Carrier</Form.Label>
              <Select
                key={'carrier-'+prescriptionId}
                className='basic-select-single'
                classNamePrefix='select'
                // defaultValue={selectedSubstatusOption}
                // value={{ value: rowItem.substatus, label: rowItem.substatus }}
                value={
                  shipping.carrier
                    ? { value: shipping.carrier, label: shipping.carrier }
                    : null
                }
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                name='carrier'
                options={getReactSelectOption(carriersList)}
                onChange={(option: ReactSelectOption | null) =>
                  reactSelectOnChange(option, 'carrier')
                }
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <h5>Address:</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formAddress1'>
              <Form.Label>address1</Form.Label>
              <Form.Control
                name='address1'
                type='text'
                value={shipping.address.address1 || ''}
                onChange={handleOnChangeAddress}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formAddress2'>
              <Form.Label>address2</Form.Label>
              <Form.Control
                name='address2'
                type='text'
                value={shipping.address.address2 || ''}
                onChange={handleOnChangeAddress}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formCity'>
              <Form.Label>city</Form.Label>
              <Form.Control
                name='city'
                type='text'
                value={shipping.address.city || ''}
                onChange={handleOnChangeAddress}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formState'>
              <Form.Label>state</Form.Label>
              <Form.Control
                name='state'
                type='text'
                value={shipping.address.state || ''}
                onChange={handleOnChangeAddress}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formZipCode'>
              <Form.Label>zipCode</Form.Label>
              <Form.Control
                name='zipCode'
                type='text'
                value={shipping.address.zipCode || ''}
                onChange={handleOnChangeAddress}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className='text-end'>
            <Button variant='success' type='submit'>
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
