import React from 'react'
import Form from 'react-bootstrap/Form'
import Pagination from 'react-bootstrap/Pagination'
import { getRandomKey } from '../../lib/utils'

type propsType = {
  dataPerPage: number
  dataPerPageHandle: (dataPerPage: number) => void
  totalData: number
  paginate: (pageNumber: number) => void
  currentPage: number
}

const CustomPagination = ({
  dataPerPage,
  dataPerPageHandle,
  totalData,
  paginate,
  currentPage,
}: propsType) => {
  const pageNumbers: any[] = []
  const prePageItems = [5, 10, 25, 50, 100]

  for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++) {
    pageNumbers.push(i)
  }

  let start = 1,
    end = pageNumbers.length
  if (currentPage - 2 > 1) {
    start = currentPage - 2
  }
  if (currentPage + 2 < pageNumbers.length) {
    end = currentPage + 2
  }

  return (
    <>
      <div className='d-flex flex-row-reverse align-items-center'>
        <div className='p-0 m-0'>

        {pageNumbers.length <= 1 ? (
              ''
            ) : (
              <Pagination className='p-0 m-0'>
                <Pagination.First
                  onClick={() => paginate(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {start !== 1 && <Pagination.Ellipsis />}
                {pageNumbers.slice(start - 1, end).map((number) => (
                  <Pagination.Item
                    key={number}
                    onClick={() => paginate(number)}
                    active={Number(currentPage) === Number(number) ? true : false}
                  >
                    {number}
                  </Pagination.Item>
                ))}
                {end !== pageNumbers.length && <Pagination.Ellipsis />}
                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === pageNumbers.length}
                />
                <Pagination.Last
                  onClick={() => paginate(pageNumbers.length)}
                  disabled={currentPage === pageNumbers.length}
                />
              </Pagination>
            )}


        </div>
        <div className='py-2 ps-0 pe-4'>
          <Form.Select
            size='sm'
            aria-label='Pre page count'
            className='sm small'
            onChange={(e) => dataPerPageHandle(Number(e.target.value))}
            value={dataPerPage}
          >
            {prePageItems?.map((item) => (
              <option value={item} key={getRandomKey()}>
                {item}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className='py-2 ps-2 pe-1 pre-page-text'>Pre page count:</div>
      </div>

    </>
  )
}

export default CustomPagination
