import React, { ReactNode } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useAuth } from '../context/auth'
import { ShowAlert } from '../components/showAlert'
import './signInStyle.scss'

type LayoutProps = {
  children: ReactNode
}

const SignInLayout = ({ children }: LayoutProps) => {
  const { resError } = useAuth()
  return (
    <div>
      <Container className='login-form'>
        <Row className='vh-100 d-flex justify-content-center align-items-center'>
          <Col md={8} lg={6} xs={12}>
            {/* <div className='border border-3 border-primary'></div> */}
            <Card className='shadow'>
              <Card.Body>{children}</Card.Body>
            </Card>
          </Col>
        </Row>
        {resError.isShow ? (
          <ShowAlert
            alertKey={resError?.status.toString() || 'show-alert-key'}
            type={'danger'}
            message={resError?.message || 'Something goes wrong'}
          />
        ) : null}
      </Container>
    </div>
  )
}

export default SignInLayout
