import React, { ReactNode } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { getRandomKey } from '../lib/utils';

type ColumProps = {
  colId: string
  fieldName: string
  currentValue: string
  // onChangeHandle: (id: string, fieldName: string, fieldValue: string) => void
  onChangeHandle: (e: React.BaseSyntheticEvent) => void,
  statusList?: string[],
  disabled?: boolean
}

export const SelectStatus = ({
  colId,
  fieldName,
  currentValue,
  onChangeHandle,
  statusList,
  disabled = false
}: ColumProps) => {
  // const statusList = ['intake', 'dispense']

  return (
    <Form.Select
      key={colId +'-select'+ getRandomKey().toString() }
      name={fieldName}
      // aria-label='Pre page count'
      // onChange={(e) => onChangeHandle(colId, fieldName, e.target.value)}
      onChange={(e) => onChangeHandle(e)}
      value={currentValue}
      disabled={disabled}
    >
      <option></option>
      {statusList?.map((item) => (
        <option value={item} key={colId +'-select-option-'+ getRandomKey().toString()}>{item}</option>
      ))}
    </Form.Select>
  )
}

export const InputText = ({
  colId,
  fieldName,
  currentValue,
  onChangeHandle,
}: ColumProps) => {
  const nameFor = 'inputText-' + colId

  return (
    <Form.Control
      key={colId +'-'+ getRandomKey().toString()}
      type='text'
      id={nameFor}
      value={currentValue}
      name={fieldName}
      // onChange={(e) => onChangeHandle(colId, fieldName, e.target.value)}
      onChange={onChangeHandle}
    />
  )
}

type ActionBtnType = {
  colId: string,
  type: string,
  title: string | ReactNode,
  onClickHandle: (id: string) => void
}
export const ActionBtn = ({colId, type = 'primary', title = '', onClickHandle, ...resProps}: ActionBtnType) => {
  return (
    <>
      <Button variant={type} onClick={(e) => onClickHandle(colId)} {...resProps}>{title}</Button>{' '}
    </>
  )
}
