import React from 'react'
import { useAuth } from '../context/auth'

import { SignRoutes } from './SignRoutes'
import { ProtectRoutes } from './ProtectRoutes'
import { Spinner } from 'react-bootstrap'

export const Routes = () => {
  const { signed, loading } = useAuth()
  const location = window.location

  let pageRef = ''
  const skipRoutes = ['/', '/signupNotForAllHi', '/!#', '!#', '']

  if (skipRoutes.includes(location.pathname) === false && signed === false) {
    pageRef = location.href
  }

  if (loading) {
    return (
      <div>
        <div>Loading...</div>
        <Spinner animation='border' variant='primary' />
      </div>
    )
  }

  return signed ? <ProtectRoutes /> : <SignRoutes pageRef={pageRef}/>
}
